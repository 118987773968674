import React from 'react';
import styles from "./aboutUs.module.scss"

const AboutUs = () => {
    return (
        <section className={styles.abUs_cont} id='aboutUs'>
            <div className={styles.abUsContent_cont}>
                <header className={styles.ab_header}>ABOUT US</header>
                <p className={styles.intro}>Sree Vyka Games Pvt. Ltd. is a gaming platform provider dedicated to crafting exceptional customer experiences and unparalleled reliability. We are passionate about creating a world-class gaming environment where players can come together, have fun, and compete in a fair and secure setting.</p>
            </div>
            
            <div className={styles.custExp_cont}>
                <header className={styles.custExp_header}>Customer Experience First</header>
                <div className={styles.cust_content}>
                    <p>At Sree Vyka Games, we prioritize the needs of our players above all else. We strive to provide a seamless and enjoyable gaming experience from the moment you sign up. This commitment is reflected in our:
                    </p>
                    <p>Intuitive and user-friendly platform: Our platform is designed to be easy to navigate, regardless of your technical expertise.</p>
                    <p>Wide variety of games: We offer a diverse selection of games to cater to all interests and preferences.</p>
                    <p>Responsive customer support: Our dedicated support team is always available to assist you with any questions or concerns you may have.</p>
                </div>
            </div>
        </section>
    );
}

export default AboutUs;
