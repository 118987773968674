import React from 'react';
import styles from "./ourMission.module.scss"
import { Helmet } from 'react-helmet';

const  OurMission = () => {
  return (
    <>
    
    <Helmet>
    <link rel="preload" href="../../assets/images/ourMission.webp" as="image" />
    </Helmet>

   <section className={styles.ourM_cont} id='ourMission'>
    <div className={styles.sub_container}>
    <header className={styles.header}>OUR <span>MISSION</span></header>
    <div className={styles.OMContent_cont}>
    <p className={styles.OMContent}>Vyka Games stands out for its unwavering commitment to both fairness and customer-centricity. Our mission prioritizes creating a trustworthy and secure platform where players can enjoy a truly satisfying gaming experience. This dedication manifests in several ways. Firstly, Vyka Games prioritizes fair play.</p>
    <p className={styles.OMContent}>We implement robust measures to ensure a level playing field for all participants, fostering a sense of trust and integrity within the gaming community. Furthermore, customer experience is paramount. Vyka Games places a strong emphasis on user satisfaction. This might be reflected in a user-friendly interface that facilitates easy navigation and gameplay, along with secure transaction methods and responsive customer support that addresses any questions or concerns promptly and efficiently. Ultimately, Vyka Games aspires to cultivate a positive and engaging environment where players can enjoy themselves with confidence.</p>
    </div>
    </div>
   </section>
   </>
  );
}

export default OurMission;
